import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ErrorsService } from '../../../shared/errors/errors.service';
import { environment as env } from '../../../../environments/environment';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class ProfileService {

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) { }

  public changePassword(oldPassword: string, password: string) {
    const url = env.base_url + '/auth/change';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };

    const passwordChanged$ = this.http.post<any>(url, {
      oldPassword: oldPassword,
      password: password,
    }, options)
    .pipe(tap(
      (data) => {},
      (error) => this.errorsService.logHttpError(url, error)
    ));
    return passwordChanged$;
  }

  // Helpers
  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new HttpHeaders(headers);
  }
}