<div class="modal-header">
  <h4 class="modal-title">Demo aanvragen</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>Vraag een demo via videomeeting aan om Esculon beter te leren kennen.</p>
</div>
<div class="modal-footer" ngbAutofocus>
  <button class="btn btn-outline-secondary" type="button" (click)="closeModal()">Later</button>
  <button class="btn btn-primary btn-block" type="submit" (click)="requestDemo()">
    <span *ngIf="!requestingDemo">Demo aanvragen</span>
    <span *ngIf="requestingDemo"><i class="fa fa-circle-o-notch fa-spinner fa-spin"></i></span>
  </button>
</div>