import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { User } from '../../models/User';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class UserService {
  public user$: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(
    public router: Router,
    public http: HttpClient,
    public errorsService: ErrorsService,
  ) {
  }

  public getUser(): Observable<UserResponse> {
    const url = env.base_url + '/auth/user';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const userFetched$ = this.http.get<UserResponse>(url, options)
      .pipe(tap(
        (response) => {
          if (response.status=='ok') {
            this.user$.next(response.data.user);
          } else {
            this.user$.next(null);
          }
        },
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return userFetched$;
  }

  public login(email: string, password: string): Observable<any> {
    const url = env.base_url + '/auth/login';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const login$ = this.http.post<any>(url, { email: email, password: password }, options)
      .pipe(tap(
        (response) => {
          if (response.status=='ok') {
            this.user$.next(response.data.user);
          } else {
            this.user$.next(null);
          }
        },
        (error) => this.errorsService.logHttpError(url, error)
      ));
    return login$;
  }

  public logout(): Observable<any> {
    const url = env.base_url + '/auth/logout';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };

    const logout$ = this.http.post<any>(url, { }, options)
      .pipe(tap(
        (data) => {
          this.user$.next(null);
        },
        (error) => this.errorsService.logHttpError(url, error)
      ));
    return logout$;
  }

  public requestReset(email: string): Observable<any> {
    const url = env.base_url + '/auth/request-reset';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };

    const requestReset$ = this.http.post<any>(url, { email: email }, options)
      .pipe(tap(
        (data) => {},
        (error) => this.errorsService.logHttpError(url, error)
      ));

    return requestReset$;
  }

  public resetPassword(token: string, email: string, password: string) {
    const url = env.base_url + '/auth/reset';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };

    const resetPassword$ = this.http.post<any>(url, {
      token: token,
      email: email,
      password: password,
    }, options)
    .pipe(tap(
      (data) => {},
      (error) => this.errorsService.logHttpError(url, error)
    ));
    return resetPassword$;
  }

  public registerUser(email: string, password: string) {
    const url = env.base_url + '/auth/register';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };

    const userRegistered$ = this.http.post<any>(url, {
      email: email,
      password: password,
    }, options)
    .pipe(tap(
      (response) => {
        if (response.status!='error') {
          this.user$.next(response.data.user);
        }
      },
      (error) => this.errorsService.logHttpError(url, error)
    ));

    return userRegistered$;
  }

  public checkTrainingAuth(token: string): Observable<checkTrainingAuthResponse> {
    const url = env.base_url + '/t/'+token+'/auth';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const trainingAuthChecked$ = this.http.get<checkTrainingAuthResponse>(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return trainingAuthChecked$;
  }

  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new HttpHeaders(headers);
  }
}

export interface UserResponse {
  status: string,
  data: {
    user: User;
  };
}

export interface checkTrainingAuthResponse {
  status: string,
}