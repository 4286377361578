import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Paymentmethod } from '../../models/Paymentmethod';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class PaymentmethodsService {
  public paymentmethods$: BehaviorSubject<Paymentmethod[]> = new BehaviorSubject([]);

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService,
  ) {}

  public getPaymentmethods(): Observable<PaymentmethodsResponse> {
    const url = env.base_url + '/paymentmethods';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const paymentmethodsFetched$ = this.http.get<PaymentmethodsResponse>(url, options)
      .pipe(tap(
        (response) => {
          this.paymentmethods$.next(response.data.paymentmethods);
        },
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return paymentmethodsFetched$;
  } 

  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new HttpHeaders(headers);
  }
}

export interface PaymentmethodsResponse {
  status,
  data: {
    paymentmethods: Paymentmethod[];
  };
}
