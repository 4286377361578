import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';

import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router,
    public userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.getUser().pipe(map(userResponse => {
      if (userResponse.status=='ok') {
        return true;
      } else {
        this.router.navigate(['/auth/login']);
      }
    }));
  }
}
