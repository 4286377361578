import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DiscoverModalComponent } from "./discover-modal/discover-modal.component";

import { TherapistsService } from '../../shared/therapists/therapists.service';
import { RecordtypesService } from '../../shared/recordtypes/recordtypes.service';
import { PaymentmethodsService } from '../../shared/paymentmethods/paymentmethods.service';
import { SalesitemsService } from '../../shared/salesitems/salesitems.service';
import { UserService } from '../../shared/user/user.service';
import { ErrorsService } from '../../shared/errors/errors.service';
import { DiscoverModalService } from './discover-modal/discover-modal.service';

import { Subscription } from 'rxjs';

import { User } from '../../models/User';
import swal from 'sweetalert';

@Component({
  selector: 'app-container',
  templateUrl: './app-container.component.html',
})
export class AppContainerComponent implements OnDestroy, OnInit  {
  public authUser: User;

  public getTherapistsSubscription: Subscription;
  public getRecordtypesSubscription: Subscription;
  public getPaymentmethodsSubscription: Subscription;
  public getSalesitemsSubscription: Subscription;
  public userSubscription: Subscription;
      
  constructor(
    public therapistsService: TherapistsService,
    public recordtypesService: RecordtypesService,
    public paymentmethodsService: PaymentmethodsService,
    public salesitemsService: SalesitemsService,
    public userService: UserService,
    public discoverModalService: DiscoverModalService,
    public modalService: NgbModal,
    public errorsService: ErrorsService,
  ) {
  }

  ngOnInit() {
    this.getTherapistsSubscription = this.therapistsService.getTherapists().subscribe(
      (response) => {},
      (error) => {
        this.errorsService.handleErrorMessage(error);
      }
    );

    this.getRecordtypesSubscription = this.recordtypesService.getRecordtypes().subscribe(
      (response) => {},
      (error) => {
        this.errorsService.handleErrorMessage(error);
      }
    );

    this.getPaymentmethodsSubscription = this.paymentmethodsService.getPaymentmethods().subscribe(
      (response) => {},
      (error) => {
        this.errorsService.handleErrorMessage(error);
      }
    );

    this.getSalesitemsSubscription = this.salesitemsService.getSalesitems().subscribe(
      (response) => {},
      (error) => {
        this.errorsService.handleErrorMessage(error);
      }
    );
  }

  ngAfterViewInit() {
    this.userSubscription = this.userService.user$.subscribe(u => {
      this.authUser = u;
      if (u && u.introStep>=1) {
        setTimeout(() => {
          this.openDiscoverModal();
        });
      }
    });
  }

  public openDiscoverModal() {
    let modalRef = this.modalService.open(DiscoverModalComponent, { backdrop: 'static' });
  }

  ngOnDestroy() {
    if (this.getTherapistsSubscription) this.getTherapistsSubscription.unsubscribe();
    if (this.getRecordtypesSubscription) this.getRecordtypesSubscription.unsubscribe();
    if (this.getPaymentmethodsSubscription) this.getPaymentmethodsSubscription.unsubscribe();
    if (this.getSalesitemsSubscription) this.getSalesitemsSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }
}
  

