import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AppContainerComponent } from './containers/app-container/app-container.component';
import { SetupContainerComponent } from './containers/setup-container/setup-container.component';
import { AuthContainerComponent } from './containers/auth-container/auth-container.component';
import { ProfileComponent } from './pages/app/profile/profile.component';

import { AuthGuard } from './shared/auth-guard/auth-guard.service';
import { SetupFinishedGuard } from './shared/setup-finished-guard/setup-finished-guard.service';
import { SetupOngoingGuard } from './shared/setup-ongoing-guard/setup-ongoing-guard.service';
import { SettingsGuard } from './shared/settings-guard/settings-guard.service';
import { CalendarGuard } from './shared/calendar-guard/calendar-guard.service';

const appRoutes: Routes = [
  { 
    path: 'auth',
    component: AuthContainerComponent,
    children: [
      { path: '', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) }
    ]
  },
  { 
    path: 'app',
    canActivate: [AuthGuard],
    children: [
      { path: 'setup', component: SetupContainerComponent, loadChildren: () => import('./pages/setup/setup.module').then(m => m.SetupModule), canActivate: [SetupOngoingGuard] },
      {
        path: '',
        component: AppContainerComponent,
        canActivate: [SetupFinishedGuard],
        children: [
          { path: 'dashboard', loadChildren: () => import('./pages/app/dashboard/dashboard.module').then(m => m.DashboardModule) },
          { path: 'patients', loadChildren: () => import('./pages/app/patients/patients.module').then(m => m.PatientsModule) },
          { path: 'calendar', loadChildren: () => import('./pages/app/calendar/calendar.module').then(m => m.CalendarModule), canActivate: [CalendarGuard] },
          { path: 'finance', loadChildren: () => import('./pages/app/finance/finance.module').then(m => m.FinanceModule) },
          { path: 'settings', loadChildren: () => import('./pages/app/settings/settings.module').then(m => m.SettingsModule), canActivate: [SettingsGuard] },
          { path: 'profile', component: ProfileComponent},
          { path: '', redirectTo: 'calendar', pathMatch: 'full' }
        ]
      }
    ]
  },
  { 
    path: 'intakes', loadChildren: () => import('./pages/intake/intake.module').then(m => m.IntakeModule)
  }, 
  { 
    path: 'online-calendar', loadChildren: () => import('./pages/online-calendar/online-calendar.module').then(m => m.OnlineCalendarModule)
  }, 
  { 
    path: 'online-payment', loadChildren: () => import('./pages/online-payment/online-payment.module').then(m => m.OnlinePaymentModule)
  }, 
  { path: 'trainingcenter', loadChildren: () => import('./pages/trainings/trainings.module').then(m => m.TrainingsModule) },
  { path: '**', redirectTo: 'app'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
