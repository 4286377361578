import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Financerecord } from '../../models/Financerecord';
import { Invoice } from '../../models/Invoice';

import { ErrorsService } from '../../shared/errors/errors.service';
import { environment as env } from '../../../environments/environment';

@Injectable()
export class RevenueService {

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) { }

  public searchRecords(patientId: number, selectTreatments: boolean, selectSalesrecords: boolean, userIds: number[], fromDate: string, toDate: string, type: string, paymentmethod: number, reference: string, clientNumber: string, page: number): Observable<FinancerecordsResponse> {
    if (fromDate===null ||toDate===null) return null;
    const url = env.base_url + '/finance/revenue/search';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let searchParams = {
      'patientId': patientId,
      'fromDate': fromDate,
      'toDate': toDate,
      'userIds': userIds,
      'selectTreatments': selectTreatments,
      'selectSalesrecords': selectSalesrecords,
      'type': type,
      'paymentmethod': paymentmethod,
      'reference': reference,
      'clientNumber': clientNumber,
      'page': page,
    }
    const searchFields$ = this.http.post<FinancerecordsResponse>(url, searchParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return searchFields$;
  }

  public getPatientInvoices(patientId: number): Observable<PatientInvoicesResponse> {
    const url = env.base_url + '/patients/'+patientId+'/invoices';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const patientInvoicesFetched$ = this.http.get<PatientInvoicesResponse>(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return patientInvoicesFetched$;
  }

  public downloadCsv(patientId: number, selectTreatments: boolean, selectSalesrecords: boolean, userIds: number[], fromDate: string, toDate: string, type: string, paymentmethod: number, reference: string, clientNumber: string): Observable<Blob> {
    const url = env.base_url + '/finance/revenue/csv';
    const options = {
      withCredentials: true,
    };
    let csvParams = {      
      'patientId': patientId,
      'fromDate': fromDate,
      'toDate': toDate,
      'userIds': userIds,
      'selectTreatments': selectTreatments,
      'selectSalesrecords': selectSalesrecords,
      'type': type,
      'paymentmethod': paymentmethod,
      'reference': reference,
      'clientNumber': clientNumber,
    };
    const csvDownloaded$ = this.http.post(url, csvParams, {responseType: 'blob', withCredentials: true})
      .pipe(map((response:Blob) => new Blob([response], { type: 'text/csv' })))
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return csvDownloaded$;
  }

  public downloadBatch(patientId: number, selectTreatments: boolean, selectSalesrecords: boolean, userIds: number[], fromDate: string, toDate: string, type: string, paymentmethod: number, reference: string, clientNumber: string): Observable<Blob> {
    const url = env.base_url + '/finance/revenue/invoices';
    const options = {
      withCredentials: true,
    };
    let batchParams = {
      'patientId': patientId,
      'fromDate': fromDate,
      'toDate': toDate,
      'userIds': userIds,
      'selectTreatments': selectTreatments,
      'selectSalesrecords': selectSalesrecords,
      'type': type,
      'paymentmethod': paymentmethod,
      'reference': reference,
      'clientNumber': clientNumber,
    };
    const batchDownloaded$ = this.http.post(url, batchParams, {responseType: 'blob', withCredentials: true})
      .pipe(map((response:Blob) => new Blob([response], { type: 'application/pdf' })))
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return batchDownloaded$;
  }

  public createBatch(patientId: number, userIds: number[], fromDate: string, toDate: string, type: string, paymentmethod: number, reference: string, clientNumber: string): Observable<FinancerecordsResponse> {
    if (fromDate===null ||toDate===null) return null;
    const url = env.base_url + '/finance/revenue/create-invoices';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let searchParams = {
      'patientId': patientId,
      'fromDate': fromDate,
      'toDate': toDate,
      'userIds': userIds,
      'type': type,
      'paymentmethod': paymentmethod,
      'reference': reference,
      'clientNumber': clientNumber,
    }
    const searchFields$ = this.http.post<FinancerecordsResponse>(url, searchParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return searchFields$;
  }

  public addSalesrecord(patientId: number, salesitemId: number, date: string, description: string, price: number, vat: number, newInvoice: boolean, invoiceId: number, hasPaid: boolean, paymentmethodId: number, paymentDate: string): Observable<any> {
    const url = env.base_url + '/patients/' + patientId + '/salesrecords';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let salesrecordParams = {
      'salesitemId': salesitemId,
      'date': date,
      'description': description,
      'price': price,
      'vat': vat,
      'newInvoice': newInvoice,
      'invoiceId': invoiceId,
      'hasPaid': !!hasPaid,
      'paymentmethodId': paymentmethodId,
      'paymentDate': paymentDate,
    };
    const salesrecordAdded$ = this.http.post<any>(url, salesrecordParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return salesrecordAdded$;
  }

  public editSalesrecord(patientId: number, salesrecordId: number, date: string, description: string, price: number, vat: number, newInvoice: boolean, invoiceId: number, hasPaid: boolean, paymentmethodId: number, paymentDate: string): Observable<any> {
    const url = env.base_url + '/patients/' + patientId + '/salesrecords/' + salesrecordId;
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let salesrecordParams = {
      'date': date,
      'description': description,
      'hasPaid': !!hasPaid,
      'price': price,
      'vat': vat,
      'newInvoice': newInvoice,
      'invoiceId': invoiceId,
      'paymentmethodId': paymentmethodId,
      'paymentDate': paymentDate,
    };
    const salesrecordEdited$ = this.http.put(url, salesrecordParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return salesrecordEdited$;
  }

  public deleteSalesrecord(patientId: number, salesrecordId: number): Observable<any> {
    const url = env.base_url + '/patients/' + patientId + '/salesrecords/' + salesrecordId;
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const salesrecordDeleted$ = this.http.delete(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return salesrecordDeleted$;
  }


  // Helpers
  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new HttpHeaders(headers);
  }
}

export interface FinancerecordsResponse {
  status,
  data: {
    allFinancerecordsNum: number,
    allFinancerecordsPaid: number,
    allFinancerecordsToPay: number,
    pagesNum: number,
    financerecords: Financerecord[],
  }
}

export interface PatientInvoicesResponse {
  status,
  data: {
    invoices: Invoice[],
  }
}