import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'auth-container',
  templateUrl: './auth-container.component.html',
})
export class AuthContainerComponent implements OnDestroy, OnInit  {
      
    constructor() {
     }
  
    ngOnInit() {      
     }
  
    ngOnDestroy() {
    }
  }
  

