import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DiscoverModalService } from './discover-modal.service';
import { ErrorsService } from '../../../shared/errors/errors.service';

import { Subscription } from 'rxjs';
import swal from 'sweetalert';

@Component({
    selector: 'discover-modal',
    templateUrl: './discover-modal.component.html',
})

export class DiscoverModalComponent {
  public requestingDemo: boolean = false;

  public requestDemoSubscription: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    public discoverModalService: DiscoverModalService,
    public errorsService: ErrorsService,
    public router: Router,
  ) {
  }

  public closeModal() {
    this.activeModal.dismiss();
  }

  public goToTrainingCenter() {
    this.activeModal.dismiss();
    window.open(window.location.origin+'/trainingcenter', '_blank');
  }

  public requestDemo() {
    this.requestingDemo = true;
    this.requestDemoSubscription = this.discoverModalService.requestDemo().subscribe(
      (response) => {
        this.requestingDemo = false;
        this.activeModal.dismiss();
        swal('Demo aangevraagd', 'We hebben uw aanvraag voor een demo via videomeeting ontvangen. We zullen zo snel mogelijk contact met u opnemen.', 'success');
      },
      (error) => {
        this.requestingDemo = false;
        this.errorsService.handleErrorMessage(error);
      }
    );
  }

  ngOnDestroy() {
    if (this.requestDemoSubscription) this.requestDemoSubscription.unsubscribe();
  }

}
