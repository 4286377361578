import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment/moment';import swal from 'sweetalert';

@Injectable()
export class ErrorsService {

  constructor(
    public router: Router,
  ) {}

  public logHttpError(route: string, error: Error): any {
    this.logError(route, error);
    if (error['status']==498) {
      console.log('Status is 498');
      location.reload();
    }
  }

  public handleErrorMessage(error?: Error) {
    if (error && error['status']==498) return;
    if (error && error['status']==401) {
      this.router.navigate(['/auth/login']);
      swal('Opnieuw inloggen', 'Je was niet meer ingelogd.\n\r\n\rGelieve opnieuw in te loggen.', 'warning');
    } else if (error && error['status']==403) {
        this.router.navigate(['/auth/login']);
        swal('Geen toegang', 'Je hebt geen toegang tot dit deel van Esculon.\n\r\n\rJe bent automatisch uitgelogd.', 'warning');
    } else if (error && error['status']==404) {
      location.href=('https://www.esculon.eu');
    } 
    else if (error && error['status']==460) {
      this.router.navigate(['/intakes/unauthenticated']);
    } else {
      if (error['status']==0) swal('Verbindingsfout', 'Het lijkt erop dat je de verbinding verloren hebt.', 'error');
      else {
        this.logError('', error);
        swal('Fout', 'Er is iets foutgelopen. Gelieve later opnieuw te proberen.', 'error');
      }
    }
  }

  public handleOverlapError(type: string) {
    if (type=='treatment') swal('Geannuleerd', 'Dit agenda-item zou overlappen met een behandeling van dezelfde therapeut.', 'warning');
    if (type=='availability') swal('Geannuleerd', 'Dit agenda-item zou overlappen met een online beschikbaarheid van dezelfde therapeut.', 'warning');
    if (type=='blocking') swal('Geannuleerd', 'Dit agenda-item zou overlappen met een blokkering van dezelfde therapeut.', 'warning');
  }

  public handleDuplicationOverlaps(dates: string[]) {
    let duplicateString = '';
    dates.forEach((date, i) => {
      if (i>0) duplicateString += ', ';
      duplicateString += moment(date).format('DD-MM-YYYY HH:mm');
    });
    swal('(Gedeeltelijk) geannuleerd', 'Deze behandelingen werden geannuleerd omdat ze zouden overlappen met een behandeling of blokkering van dezelfde therapeut: '+duplicateString, 'warning');
  }

  public handleRecordDoesNotExistError() {
    swal('Geannuleerd', 'Dit record kon niet worden gevonden. Het werd mogelijks verwijderd. Je wordt doorverwezen naar het overzicht.', 'warning');
  }

  public handleEmailDuplicateError() {
    swal('Geannuleerd', 'Dit e-mailadres is al gebruikt door een andere medewerker.', 'warning');
  }

  public handleDeleteAuthenticatedAccountError() {
    swal('Geannuleerd', 'Je kan je eigen gebruikersaccount niet verwijderen.', 'warning');
  }

  public handleInvalidDateError() {
    swal('Geannuleerd', 'Deze datum is niet geldig.', 'warning');
  }

  public handleInvalidEmailError() {
    swal('Geannuleerd', 'Dit e-mailadres is niet geldig.', 'warning');
  }

  public handleSendIntakeError(type: string) {
    if (type=='anamnese_in_past') swal('Geannuleerd', 'Het intakeformulier werd niet verstuurd omdat de anamnese in het verleden ligt.', 'warning');
    if (type=='no_email_address') swal('Geannuleerd', 'Het intakeformulier kon niet worden verstuurd omdat deze patiënt geen e-mailadres heeft.', 'warning');
    if (type=='invalid_email_address') swal('Geannuleerd', 'Het intakeformulier kon niet worden verstuurd omdat het e-mailadres van de patiënt niet geldig is.', 'warning');
  }

  public handleSendCommunicationError(type: string) {
    swal('Geannuleerd', 'De e-mail kon niet worden verstuurd omdat het e-mailadres niet geldig is.', 'warning');
  }

  public handleUploadAttachmentError() {
    swal('Geannuleerd', 'Er is iets misgelopen bij het uploaden van de bijlage.', 'warning');
  }

  public handleSendInvoiceError(type: string) {
    if (type=='no_email_address') {
      swal('Geannuleerd', 'De factuur kon niet worden verstuurd omdat deze patiënt geen e-mailadres heeft.', 'warning');
    }
    if (type=='invalid_email_address') {
      swal('Geannuleerd', 'De factuur kon niet worden verstuurd omdat het e-mailadres van de patiënt niet geldig is.', 'warning');
    }
  }

  public handleSendPaymentReminderError(type: string) {
    if (type=='no_email_address') {
      swal('Geannuleerd', 'De betalingsherinnering kon niet worden verstuurd omdat deze patiënt geen e-mailadres heeft.', 'warning');
    }
    if (type=='invalid_email_address') {
      swal('Geannuleerd', 'De betalinsherinnering kon niet worden verstuurd omdat het e-mailadres van de patiënt niet geldig is.', 'warning');
    }
  }

  public handleSendAppointmentReminderError(type: string) {
    if (type=='treatment_in_past') {
      swal('Geannuleerd', 'De afspraakherinnering kon niet worden verstuurd omdat de behandeling in het verleden ligt.', 'warning');
    }
    if (type=='no_email_address') {
      swal('Geannuleerd', 'De afspraakherinnering kon niet worden verstuurd omdat deze patiënt geen e-mailadres heeft.', 'warning');
    }
    if (type=='invalid_email_address') {
      swal('Geannuleerd', 'De afspraakherinnering kon niet worden verstuurd omdat het e-mailadres van de patiënt niet geldig is.', 'warning');
    }
    if (type=='no_phone') {
      swal('Geannuleerd', 'De afspraakherinnering kon niet worden verstuurd omdat deze patiënt geen telefoonnummer heeft.', 'warning');
    }
    if (type=='invalid_phone') {
      swal('Geannuleerd', 'De afspraakherinnering kon niet worden verstuurd omdat het telefoonnummer van de patiënt niet geldig is.', 'warning');
    }
  }

  public handleAcceptRequestError(type: string) {
    if (type=='no_email_address') {
      swal('Geannuleerd', 'De patiënt kon niet worden gemaild omdat deze patiënt geen e-mailadres heeft.', 'warning');
    }
  }

  public handleDeclineRequestError(type: string) {
    if (type=='no_email_address') {
      swal('Geannuleerd', 'De patiënt kon niet worden gemaild omdat deze patiënt geen e-mailadres heeft.', 'warning');
    }
  }

  public handleAddWaitinglistitemError(type: string) {
    if (type=='waitinglistitem_exists') {
      swal('Geannuleerd', 'De patiënt kon niet worden toegevoegd aan de wachtlijst omdat de patiënt reeds op de wachtlijst staat.', 'warning');
    }
  }

  public handleEditInvoiceError(type: string) {
    if (type=='duplicate_reference') {
      swal('Geannuleerd', 'De factuur kon niet gewijzigd worden omdat een andere factuur reeds dit factuurnummer heeft.', 'warning');
    }
  }


  public logError(scope: string, error: Error) {
    console.error('=== Start error: ', scope, ' ===');
    console.error(error);
    console.error('=== End error: ', scope, ' ===');
  }

}
