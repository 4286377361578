import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class FieldService {

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) { }

  public updateFieldvalue(fieldvalueParams: any, intake: boolean): Observable<any> {
    let url = env.base_url;
    if (intake) url+= '/intake';
    url += '/fieldvalues';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const fieldvalue$ = this.http.put(url, fieldvalueParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return fieldvalue$;
  }

  // Helpers
  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new HttpHeaders(headers);
  }
}
