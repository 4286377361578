<div class="app-content container center-layout mt-2 mb-2">
    <div class="content-wrapper">
        <div class="content-header row"></div>
        <div class="content-body">
            <section class="col-11 col-md-8 col-lg-5 ml-auto mr-auto box-shadow-2 p-0">
                <div class="card border-grey border-lighten-3 px-1 py-2 row mb-3">
                    <router-outlet></router-outlet>
                </div>
            </section>
        </div>
    </div>
</div>