import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Fieldcategory } from '../../models/Fieldcategory';
import { Field } from '../../models/Field';
import { Fieldvalue } from '../../models/Fieldvalue';
import { FormItem } from '../../models/FormItem';
import { Drawing } from '../../models/Drawing';
import { Element } from '../../models/Element';
import { Alert } from '../../models/Alert';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class RecordsOverviewService {
  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) { }

  public getPatientRecordsOverview(patientId: number, beforeRecordId: number): Observable<PatientRecordsOverviewResponse> {
    if (!beforeRecordId) beforeRecordId = 0;
    const url = env.base_url + '/patients/' + patientId + '/records/overview/before/'+ beforeRecordId;
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const patientRecordsSummary$ = this.http.get<PatientRecordsOverviewResponse>(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return patientRecordsSummary$;
  }

  public getAlert(patientId: number): Observable<AlertResponse> {
    const url = env.base_url + '/patients/'+patientId+'/alert';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const alertFetched$ = this.http.get<AlertResponse>(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return alertFetched$;
  }

  // Helpers
  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new HttpHeaders(headers);
  }
}


export interface PatientRecordsOverviewResponse {
  status,
  data: {
    records: RecordSummary[]
  }
}

export interface RecordSummary {
  recordId: number,
  start: string,
  end: string,
  type: string,
  tbc: boolean,
  recordtypeName: string,
  fieldcategories: Fieldcategory[],
  fields: Field[],
  fieldvalues: Fieldvalue[],
  formItems?: FormItem[],
  activeDrawings?: Drawing[],
  activeElements?: Element[],
}

export interface AlertResponse {
  status,
  data: {
    alert: Alert;
  };
}