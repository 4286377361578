import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'setup-container',
  templateUrl: './setup-container.component.html',
})
export class SetupContainerComponent implements OnDestroy, OnInit  {
      
    constructor() {
     }
  
    ngOnInit() {      
     }
  
    ngOnDestroy() {
    }
  }
  

