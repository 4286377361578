import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';


import { Event } from '../../models/Event';
import { Slot } from '../../models/Slot';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

@Injectable()
export class FindPatientsService {
  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) {}

  public findPatients(query: string): Observable<PatientsFoundResponse> {
    const url = env.base_url + '/patients/find?q=' + query ;
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const patientsFound$ = this.http.get<PatientsFoundResponse>(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return patientsFound$;
  }

  // Helpers
  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new HttpHeaders(headers);
  }
}

export interface PatientsFoundResponse {
  status,
  data: {
    patients: PatientFound[]
  }
}

export interface PatientFound {
  patientId: number,
  patientName: string
}