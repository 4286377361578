import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

@Injectable()
export class NewAnamneseModalService {

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) {}

  public addAnamnese(patientId: number, userId: number, recordtypeId: number, date: string, sendIntake: boolean): Observable<any> {
    const url = env.base_url + '/patients/' + patientId + '/records';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let anamneseParams = {
      'start': date+' 00:00',
      'end': date+' 00:00',
      'userId': userId,
      'recordtypeId': recordtypeId,
      'sendIntake': sendIntake
    }
    const anamneseAdded$ = this.http.post<any>(url, anamneseParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return anamneseAdded$;
  }
  
  // Helpers
  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new HttpHeaders(headers);
  }
}