import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from './shared/locale/locale.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    public translateService: TranslateService,
    public localeService: LocaleService
  ) {
    translateService.setDefaultLang('nl');
    translateService.use(localeService.getLanguage());
  }
}
