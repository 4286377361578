<div role="navigation" data-menu="menu-wrapper" class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-static navbar-light navbar-without-dd-arrow navbar-shadow menu-border" style="margin-bottom: 1px">
  <div class="navbar-container main-menu-content container px-0 center-layout">
    <ul id="main-menu-navigation" data-menu="menu-navigation" class="nav navbar-nav" style="flex-direction: row">
      <li *ngIf="authUser" class="nav-item ml-1 mr-1" style="margin-top: 6px"><a routerLink="/app/dashboard" class="navbar-brand"><img [src]="assetsUrl+'/app/images/logo/esculon-logo-small.png'" class="brand-logo"></a></li>
      <li *ngIf="authUser" class="nav-item mr-1" routerLinkActive="active">
        <a routerLink="/app/dashboard" class="nav-link" tourAnchor="dashboardNavLink">
          <span class="d-none d-sm-block"><i class="ft-bar-chart-2"></i>Dashboard</span>
          <span class="d-block d-sm-none"><i class="ft-bar-chart-2"></i></span>
        </a>
      </li>
      <li class="nav-item mr-1" routerLinkActive="active" tourAnchor="patientsNavLink">
        <a routerLink="/app/patients" class="nav-link">
          <span class="d-none d-sm-block"><i class="ft-user"></i>Patiënten</span>
          <span class="d-block d-sm-none"><i class="ft-user"></i></span>
        </a>
      </li>
      <li *ngIf="authUser && authUser.accessToCalendar" class="nav-item mr-1" routerLinkActive="active" tourAnchor="calendarNavLink">
        <a routerLink="/app/calendar" class="nav-link">
          <span class="d-none d-sm-block"><i class="ft-calendar"></i>Agenda</span>
          <span class="d-block d-sm-none"><i class="ft-calendar"></i></span>
        </a>
      </li>
      <li *ngIf="authUser && authUser.accessToFinancials" class="nav-item mr-1" routerLinkActive="active" tourAnchor="financeNavLink">
        <a routerLink="/app/finance" class="nav-link">
          <span class="d-none d-sm-block"><i class="ft-file-text"></i>Financieel</span>
          <span class="d-block d-sm-none"><i class="ft-file-text"></i></span>
        </a>
      </li>
    </ul>
    <ul id="main-menu-navigation" data-menu="menu-navigation" class="nav navbar-nav float-right" style="flex-direction: row">
        <li *ngIf="authUser && authUser.accessToSettings" class="nav-item mr-1" routerLinkActive="active" tourAnchor="settingsNavLink">
          <a routerLink="/app/settings" class="nav-link"><i class="ft-settings mr-0"></i></a>
        </li>
        <li class="dropdown dropdown-user nav-item" tourAnchor="profileNavLink">
            <a *ngIf="authUser" href="#" data-toggle="dropdown" class="dropdown-toggle nav-link dropdown-user-link">
                <span class="d-none d-sm-block">{{ authUser.firstName }} {{ authUser.lastName }}</span>
                <span class="d-block d-sm-none"><i class="ft-briefcase"></i></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" (click)="discover()"><i class="ft-book"></i> Demo aanvragen</a>
              <a routerLink="/app/profile" class="dropdown-item"><i class="ft-user"></i> Wachtwoord wijzigen</a>
              <a class="dropdown-item" (click)="goToUserAgreement()"><i class="ft-file-text"></i> Gebruiksovereenkomst</a>
              <a class="dropdown-item" (click)="logout()"><i class="ft-power"></i> Uitloggen</a>
            </div>
          </li>
      </ul>
  </div>
</div>

<div *ngIf="authUser && authUser.daysToTrialEnd>-1 && showTrialAlert" class="container">
  <div class="alert alert-warning my-1">
    <span *ngIf="authUser.daysToTrialEnd==0">Dit is de laatste dag van je proefversie.</span>
    <span *ngIf="authUser.daysToTrialEnd==1">Je proefversie van Esculon is nog 1 dag beschikbaar.</span>
    <span *ngIf="authUser.daysToTrialEnd>1">Je proefversie van Esculon is nog {{authUser.daysToTrialEnd}} dagen beschikbaar.</span>
    Contacteer <a href="mailto:support@esculon.eu" class="alert-link">support@esculon.eu</a> om over te schakelen naar een jaarlijkse licentie.
  </div>
</div>