import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Invoice } from '../../models/Invoice';

import { ErrorsService } from '../../shared/errors/errors.service';
import { environment as env } from '../../../environments/environment';

@Injectable()
export class InvoicesService {

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) { }

  public searchInvoices(patientId: number, fromDate: string, toDate: string, reference: string, clientNumber: string, page: number): Observable<InvoicesResponse> {
    const url = env.base_url + '/finance/invoices/search';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let searchParams = {
      'patientId': patientId,
      'fromDate': fromDate,
      'toDate': toDate,
      'reference': reference,
      'clientNumber': clientNumber,
      'page': page,
    }
    const invoicesFound$ = this.http.post<InvoicesResponse>(url, searchParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return invoicesFound$;
  }

  public getPatientInvoices(patientId: number): Observable<PatientInvoicesResponse> {
    const url = env.base_url + '/patients/'+patientId+'/invoices';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const patientInvoicesFetched$ = this.http.get<PatientInvoicesResponse>(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return patientInvoicesFetched$;
  }

  public getInvoice(invoiceId: number): Observable<InvoiceResponse> {
    const url = env.base_url + '/finance/invoices/' + invoiceId;
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const invoice$ = this.http.get<InvoiceResponse>(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return invoice$;
  }

  public editInvoice(invoiceId: number, reference: string, date: string, userId: number): Observable<any> {
    const url = env.base_url + '/finance/invoices/'+invoiceId;
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let invoiceParams = {
      'reference': reference,
      'date': date,
      'userId': userId,
    }
    const invoiceEdited$ = this.http.put(url, invoiceParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return invoiceEdited$;
  }

  public setInvoiceLock(invoiceId: number, locked: boolean): Observable<any> {
    const url = env.base_url + '/finance/invoices/'+invoiceId+'/lock';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let invoiceLockParams = {
      'locked': locked,
    }
    const invoiceLockSet$ = this.http.put(url, invoiceLockParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return invoiceLockSet$;
  }

  public deleteInvoice(invoiceId: number): Observable<any> {
    const url = env.base_url + '/finance/invoices/'+invoiceId;
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const invoiceDeleted$ = this.http.delete(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return invoiceDeleted$;
  }

  public unlinkFinancerecord(invoiceId: number, recordId: number, salesrecordId: number): Observable<any> {
    const url = env.base_url + '/finance/invoices/'+invoiceId+'/unlink';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let invoiceParams = {
      'recordId': recordId,
      'salesrecordId': salesrecordId,
    }
    const unlinked$ = this.http.put(url, invoiceParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return unlinked$;
  }

  public downloadInvoice(invoiceId: number): Observable<Blob> {
    const url = env.base_url + '/finance/invoices/' + invoiceId + '/download';
    let invoiceParams = {};
    const invoiceDownloaded$ = this.http.post(url, invoiceParams, {responseType: 'blob', withCredentials: true})
      .pipe(map((response:Blob) => new Blob([response], { type: 'application/pdf' })))
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return invoiceDownloaded$;
  }

  public downloadBatch(patientId: number, fromDate: string, toDate: string, reference: string, clientNumber: string): Observable<Blob> {
    const url = env.base_url + '/finance/invoices/download';
    let batchParams = {
      'patientId': patientId,
      'fromDate': fromDate,
      'toDate': toDate,
      'reference': reference,
      'clientNumber': clientNumber,
    };
    const batchDownloaded$ = this.http.post(url, batchParams, {responseType: 'blob', withCredentials: true})
      .pipe(map((response:Blob) => new Blob([response], { type: 'application/pdf' })))
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return batchDownloaded$;
  }

  public mailInvoice(invoiceId: number, message: string): Observable<any> {
    const url = env.base_url + '/finance/invoices/' + invoiceId + '/mail';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let mailParams = {
      message: message,
    };
    const invoiceMailed$ = this.http.post<any>(url, mailParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return invoiceMailed$;
  }

  public remindInvoice(invoiceId: number, message: string): Observable<any> {
    const url = env.base_url + '/finance/invoices/' + invoiceId + '/remind';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let remindParams = {
      message: message,
    };
    const invoiceReminded$ = this.http.post<any>(url, remindParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return invoiceReminded$;
  }

  public addCreditinvoice(patientId: number, date: string, invoiceId: number): Observable<any> {
    const url = env.base_url + '/patients/' + patientId + '/creditinvoices';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    let creditinvoiceParams = {
      'date': date,
      'invoiceId': invoiceId,
    };
    const creditinvoiceAdded$ = this.http.post<any>(url, creditinvoiceParams, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return creditinvoiceAdded$;
  }

  // Helpers
  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new HttpHeaders(headers);
  }
}

export interface InvoicesResponse {
  status,
  data: {
    allInvoicesNum: number,
    allInvoicesRevenue, number,
    pagesNum: number,
    invoices: Invoice[],
  }
}

export interface InvoiceResponse {
  status,
  data: {
    invoice: Invoice,
  }
}

export interface PatientInvoicesResponse {
  status,
  data: {
    invoices: Invoice[],
  }
}