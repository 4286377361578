import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { User } from '../../models/User';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class TherapistsService {
  public therapists$: BehaviorSubject<User[]> = new BehaviorSubject([]);

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService,
  ) {}

  public getTherapists(): Observable<TherapistsResponse> {
    const url = env.base_url + '/therapists';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };

    const therapistsFetched$ = this.http.get<TherapistsResponse>(url, options)
      .pipe(tap(
        (response) => {
          this.therapists$.next(response.data.therapists);
        },
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return therapistsFetched$;
  } 

  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new HttpHeaders(headers);
  }
}

export interface TherapistsResponse {
  status,
  data: {
    therapists: User[];
  };
}
