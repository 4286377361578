import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Field } from '../../../models/Field';
import { Patient } from '../../../models/Patient';

import { ErrorsService } from '../../../shared/errors/errors.service';
import { environment as env } from '../../../../environments/environment';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class DiscoverModalService {

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) { }

  public requestDemo(): Observable<any> {
    const url = env.base_url + '/discover/demo';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const demoRequested$ = this.http.post<any>(url, null, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return demoRequested$;
  }

  public getPatient(): Observable<any> {
    const url = env.base_url + '/discover/patient';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const patient$ = this.http.get<PatientResponse>(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return patient$;
  }

  // Helpers
  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new HttpHeaders(headers);
  }
}

export interface PatientResponse {
  status: boolean,
  data: {
    patient: {
      patientId: number,
      anamneseId: number,
      treatmentId: number
    }
  }
}