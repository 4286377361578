import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Salesitem } from '../../models/Salesitem';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SalesitemsService {
  public salesitems$: BehaviorSubject<Salesitem[]> = new BehaviorSubject([]);

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService,
  ) {}

  public getSalesitems(): Observable<SalesitemsResponse> {
    const url = env.base_url + '/salesitems';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };

    const salesitemsFetched$ = this.http.get<SalesitemsResponse>(url, options)
      .pipe(tap(
        (response) => {
          this.salesitems$.next(response.data.salesitems);
        },
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return salesitemsFetched$;
  } 

  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new HttpHeaders(headers);
  }
}

export interface SalesitemsResponse {
  status,
  data: {
    salesitems: Salesitem[];
  };
}
