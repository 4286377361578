import { Injectable } from '@angular/core';
import { lang } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor() { }

  public getLanguage() {
    let lang = navigator.language;
    if (lang == 'fr-FR' || lang == 'fr-CA' || lang == 'fr-CH') lang = 'fr';
    if (lang != 'nl' && lang != 'fr') lang = 'nl';
    if (localStorage.getItem('language')) {
      lang = localStorage.getItem('language');
    } else {
      localStorage.setItem('language', lang);
    }
    return lang;
  }

  public setLanguage(lang: string) {
    localStorage.setItem('language', lang);
  }

  public mapNameToLanguage(objects: any, altlang: string) {
    if (this.getLanguage()==altlang) {
      objects.map(obj => obj.name = (obj.nameAltlang ? obj.nameAltlang : obj.name))
    }
    return objects;
  }
}