import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Recordlog } from '../../models/Recordlog';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class RecordlogsService {

  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService,
  ) {}

  public getRecordlogs(patientId: number, recordId: number): Observable<RecordlogsResponse> {
    const url = env.base_url + '/patients/' + patientId + '/records/' + recordId + '/recordlogs';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const recordlogsFetched$ = this.http.get<RecordlogsResponse>(url, options)
      .pipe(tap(
        (response) => {},
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return recordlogsFetched$;
  } 

  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new HttpHeaders(headers);
  }
}

export interface RecordlogsResponse {
  status,
  data: {
    recordlogs: Recordlog[];
    record: {
      start: string,
      end: string,
      patientName: string,
    }
  };
}
