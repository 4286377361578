import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';

import { map } from 'rxjs/operators';

@Injectable()
export class CalendarGuard implements CanActivate {

  constructor(
    public router: Router,
    public userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.user$.pipe(map(u => {
      if (u && u.accessToCalendar) {
        return true
      } else {
        this.router.navigateByUrl('/app/patients');
      }
    }));
  }
}
