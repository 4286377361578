import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Position } from '../../models/Position';
import { Standardline } from '../../models/Standardline';
import { Drawing } from '../../models/Drawing';
import { Drawingcategory } from '../../models/Drawingcategory';
import { Line } from '../../models/Line';

import { ErrorsService } from '../errors/errors.service';
import { environment as env } from '../../../environments/environment';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class EritConfigService {
  public config$: BehaviorSubject<Config> = new BehaviorSubject(null);
  constructor(
    public http: HttpClient,
    public errorsService: ErrorsService
  ) {}

  public getConfig(): Observable<ConfigResponse> {
    const url = env.base_url + '/erit/config';
    const options = {
      headers: this.getHeaders(),
      withCredentials: true
    };
    const configData$ = this.http.get<ConfigResponse>(url, options)
      .pipe(tap(
        (response) => {
          this.config$.next(response.data);
        },
        (error) => {
          this.errorsService.logHttpError(url, error);
        }
      ));
    return configData$;
  }

  // Helpers
  public getHeaders(): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new HttpHeaders(headers);
  }
}

export interface ConfigResponse {
  status,
  data: Config,
}

export interface Config {
  positions: Position[],
  standardlines: Standardline[],
  drawings: Drawing[],
  drawingcategories: Drawingcategory[],
  lines: Line[],
}