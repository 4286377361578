import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, FormArray } from '@angular/forms';

import { ErrorsService } from '../../../shared/errors/errors.service';
import { ProfileService } from './profile.service';
import { environment as env } from '../../../../environments/environment';

import { User } from '../../../models/User';

import { Subscription } from 'rxjs';
import swal from 'sweetalert';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent {
  public form: FormGroup;
  public loading: boolean = false;
  public status: string;

  public oldPassword: AbstractControl;
  public password: AbstractControl;

  public changePasswordSubscription: Subscription;
  public oldPasswordValueChangesSubscription: Subscription;

  constructor(
    public profileService: ProfileService,
    public errorsService: ErrorsService,
    public fb: FormBuilder,
    public route: ActivatedRoute,
  ) {
    this.form = fb.group({
      oldPassword: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      passwordConfirmation: ['']
    });
    this.oldPassword = this.form.controls['oldPassword'];
    this.password = this.form.controls['password'];
  }

  ngOnInit() {
    this.oldPasswordValueChangesSubscription = this.oldPassword.valueChanges.subscribe(value => this.status = null);
  }

  public changePassword() {
    if (this.form.valid) {
      this.loading = true;
      this.changePasswordSubscription = this.profileService.changePassword(this.oldPassword.value, this.password.value).subscribe(
        (response) => {
          this.loading = false;
          if (response.status=='ok') {
            this.form.reset();
          } else if (response.error=='old_password_not_valid') {
            this.oldPassword.reset();
          }
          this.status = response.status=='error' ? response.error : 'success';
        },
        (error) => {
            this.errorsService.handleErrorMessage(error);
            this.loading = false;
        }
      );
    }
    
  }

  ngOnDestroy() {
    if (this.changePasswordSubscription) this.changePasswordSubscription.unsubscribe();
    if (this.oldPasswordValueChangesSubscription) this.oldPasswordValueChangesSubscription.unsubscribe();
  }
}
