<div class="app-content container center-layout mt-2 mb-2">
  <div class="content-wrapper">
    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-content">
              <div class="card-body">
                  <div class="card-text">
                    <form (ngSubmit)="changePassword()">
                      <div class="form-body">
                        <h5>Wachtwoord wijzigen</h5>
                        <div class="col-12 col-lg-6">
                          <div class="row">
                              <p class="text-success" *ngIf="status=='success'">Je nieuw wachtwoord is opgeslagen.</p>
                              <p *ngIf="status=='old_password_not_valid'" class="text-danger">Je oud wachtwoord is niet correct.</p>
                              <p *ngIf="!status"><br></p>
                          </div>
                          <div class="form-group row">
                            <label class="small font-weight-bold mb-0" for="therapist">Oud wachtwoord <span class="text-danger">*</span></label>
                            <input type="password" autocomplete="off" [formControl]="oldPassword" class="form-control" name="oldPassword" /> 
                          </div>
                          <div class="form-group row">
                            <label class="small font-weight-bold mb-0" for="therapist">Nieuw wachtwoord (minstens 8 karakters) <span class="text-danger">*</span></label>
                            <input type="password" autocomplete="off" [formControl]="password" class="form-control" name="password" />
                          </div>
                          <div class="form-group row">
                            <button type="submit" [disabled]="!form.valid || loading" class="btn btn-primary">
                              <span *ngIf="!loading">Opslaan</span>
                              <span *ngIf="loading"><i class="fa fa-circle-o-notch fa-spinner fa-spin"></i></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>